<template>
  <div>
    <!-- Modal del formulario -->
    <div :class="{'modal': true, 'is-active': true}">
      <div class="modal-background"></div>
      <div class="modal-content">
        <div class="box" style="max-width: 450px; margin: auto;">

          <div class="field">
            <img :src="propsImage">
          </div>

          <div class="columns">
            <div class="column">
            </div>
            <div class="column">
              <button class="button is-danger is-outlined is-fullwidth is-rounded is-medium" @click="close">Cerrar</button>
            </div>
          </div>
        </div>
      </div>
      <button class="modal-close is-large" aria-label="close" @click="close"></button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    close: {
      type: Function,
      default: () => {}
    },
    propsImage: {
      type: String,
      default: () => {}
    }
  }
}
</script>

<style scoped lang="scss">
.box {
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}
.field {
  margin-bottom: 1rem;
}
.button.is-fullwidth {
  width: 100%;
}
.modal-background {
  background-color: rgba(0, 0, 0, 0.5);
}
.is-danger{
  background-color: #EB5965;
}
</style>
